'use client'

import type { SellersDataProps } from '../auctionNavData/sellersData'
import {
  SellersContainer,
  SubTitle,
  Item,
  ItemLink,
  AllSellersList,
  SellersColumn,
  SellersDivider,
  TopSellersList,
} from '../ui'

interface SellersLayoutProps {
  data: SellersDataProps
}

const SellersLayout: React.FC<SellersLayoutProps> = ({
  data,
}: {
  data: SellersDataProps
}) => {
  return (
    <SellersContainer>
      <SellersColumn>
        <SubTitle>{data.topSellersHeader.title}</SubTitle>
        <TopSellersList>
          {data.topSellers.map((item) => (
            <Item key={item.name}>
              <ItemLink
                href={item.href}
                target="_blank"
                rel="noopener"
                aria-label={`View top seller ${item.ariaLabel} in a new tab`}
              >
                {item.name}
              </ItemLink>
            </Item>
          ))}
        </TopSellersList>
      </SellersColumn>
      <SellersDivider />
      <SellersColumn>
        <SubTitle>{data.allSellersHeader.title}</SubTitle>
        <AllSellersList>
          {data.allSellers.map((item) => (
            <Item key={item.name}>
              <ItemLink
                href={item.href}
                target="_blank"
                rel="noopener"
                aria-label={`View seller ${item.ariaLabel} in a new tab`}
              >
                {item.name}
              </ItemLink>
            </Item>
          ))}
        </AllSellersList>
      </SellersColumn>
    </SellersContainer>
  )
}

export default SellersLayout
