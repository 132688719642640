export interface Seller {
  logo: string
  name: string
  link: string
  color: string
}

// The colors here are not currently in our designColors.

const baseSellerData: Seller[] = [
  {
    logo: '/home-portal/images/sellerIcons/amazon-cir.png',
    name: 'Amazon',
    link: 'https://bstock.com/amazon/',
    color: '#FFFAEE',
  },
  {
    logo: '/home-portal/images/sellerIcons/Target.png',
    name: 'Target',
    link: 'https://bstock.com/target/',
    color: '#ffeaee',
  },
  {
    logo: '/home-portal/images/sellerIcons/att-logo.png',
    name: 'Mobile Carrier',
    link: 'https://bstock.com/mobilecarrier/',
    color: '#dff4ff',
  },
  {
    logo: '/home-portal/images/sellerIcons/Homedepot.png',
    name: 'Home Depot',
    link: 'https://bstock.com/home-depot/',
    color: '#FFF4EE',
  },
  {
    logo: '/home-portal/images/sellerIcons/Unilever.png',
    name: 'Unilever',
    link: 'https://bstock.com/unilever/',
    color: '#F0F5FF',
  },
  {
    logo: '/home-portal/images/sellerIcons/Bjs.png',
    name: "BJ's",
    link: 'https://bstock.com/buy/sellers/details/bjs',
    color: '#ffebec',
  },
  {
    logo: '/home-portal/images/sellerIcons/Ge.png',
    name: 'GE',
    link: 'https://bstock.com/geappliances/',
    color: '#F0F5FF',
  },
  {
    logo: '/home-portal/images/sellerIcons/walmart.png',
    name: 'Walmart',
    link: 'https://liquidations.walmart.com/',
    color: '#ffebec',
  },
  {
    logo: '/home-portal/images/sellerIcons/samsung.png',
    name: 'Samsung',
    link: 'https://bstock.com/samsung/',
    color: '#F0F5FF',
  },
  {
    logo: '/home-portal/images/sellerIcons/costco.png',
    name: 'Costco',
    link: 'https://bstock.com/costco/',
    color: '#ffeaee',
  },
]

export const sellersSpotlight = Array.from({ length: 9 }, (_, index) => {
  const baseSeller = baseSellerData[index % baseSellerData.length]
  return {
    ...baseSeller,
    key: `${baseSeller.name} ${Math.floor(index / baseSellerData.length) + 1}`,
  }
})
