'use client'

import CategoriesLayout from './CategoriesLayout'
import ConditionsLayout from './ConditionsLayout'
import RegionsLayout from './RegionsLayout'
import SellersLayout from './SellersLayout'
import type { AllCategoriesDataProps } from '../auctionNavData/categoriesData'
import type { AllConditionsDataProps } from '../auctionNavData/conditionsData'
import type { AllRegionsDataProps } from '../auctionNavData/regionsData'
import type { SellersDataProps } from '../auctionNavData/sellersData'
import { SubMenuContainer } from '../ui'

interface BaseSubMenuProps {
  layout:
    | 'single-column'
    | 'two-column-wrap'
    | 'single-column-wrapped'
    | 'three-column'
  $fullWidth?: boolean
  $alignRight?: boolean
}

interface SubMenuPropsWithConditions extends BaseSubMenuProps {
  layout: 'single-column'
  data: AllConditionsDataProps
}

interface SubMenuPropsWithSellers extends BaseSubMenuProps {
  layout: 'two-column-wrap'
  data: SellersDataProps
}

interface SubMenuPropsWithCategories extends BaseSubMenuProps {
  layout: 'single-column-wrapped'
  data: AllCategoriesDataProps
}

interface SubMenuPropsWithRegions extends BaseSubMenuProps {
  layout: 'three-column'
  data: AllRegionsDataProps
}

type AllSubMenuProps =
  | SubMenuPropsWithConditions
  | SubMenuPropsWithSellers
  | SubMenuPropsWithCategories
  | SubMenuPropsWithRegions

const SubMenu = ({
  layout,
  data,
  $fullWidth,
  $alignRight,
}: AllSubMenuProps) => {
  return (
    <SubMenuContainer $fullWidth={$fullWidth} $alignRight={$alignRight}>
      {layout === 'single-column' && <ConditionsLayout data={data} />}
      {layout === 'two-column-wrap' && <SellersLayout data={data} />}
      {layout === 'single-column-wrapped' && <CategoriesLayout data={data} />}
      {layout === 'three-column' && <RegionsLayout data={data} />}
    </SubMenuContainer>
  )
}

export default SubMenu
