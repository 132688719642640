'use client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { styled } from 'styled-components'

import { designColors, typography, shadows } from '@b-stock/bstock-react/theme'

//NOTE: Our design system breakpoints don't meet the needs of this nav design.

export const Overlay = styled.div<{ onClick: () => void }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`

export const NavSubMenuIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
  font-size: 0.625rem;
  max-width: 0.75rem;
  max-height: 0.75rem;
`

export const AuctionNavDivider = styled.div<{ $height?: string }>`
  width: 0.0625rem;
  height: ${({ $height }) => $height || '50%'};
  background-color: ${designColors.neutral.mediumGray};
  margin: 0;

  @media screen and (max-width: 1200px) {
    border: 0.0625rem solid ${designColors.neutral.mediumGray};
  }
`

export const ScrollableNavbarContainer = styled.div<{ $isScrollable: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  @media screen and (max-width: 1200px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    max-height: 100%; // or a specific height
    overflow-y: visible;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-around;
  height: 2.6875rem;
  max-height: 2.6875rem;
  background: ${designColors.neutral.white};
  overflow-y: visible;
`

export const NavbarUl = styled.ul`
  list-style: none;
  margin: 0 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.875rem;
  max-width: 88.125rem;
  height: 100%;
  max-height: 100%;

  @media screen and (max-width: 1200px) {
    flex-wrap: nowrap;
    width: fit-content;
  }
`

export const NavbarLink = styled(Link)`
  position: relative;
  ${typography.subtitle3};
  text-decoration: none;
  color: black;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
  line-height: 0;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.1875rem;
    left: 0;
    bottom: -0.1875rem;
    background-color: ${designColors.primary.default};
    transition: width 0.3s ease-in-out;
  }

  &:hover:after {
    width: 100%;
  }
`

// Desktop SubMenu Styles
export const SubMenuContainer = styled.div<{
  $fullWidth?: boolean
  $alignRight?: boolean
}>`
  position: absolute;
  ${({ $fullWidth, $alignRight }) =>
    $fullWidth
      ? `
        left: 0rem;
        right: 2.5rem;
        width: 100vw;
        transform: none;
      `
      : $alignRight
        ? `
        right: 0;
        left: auto;
        transform: translateX(0);
        width: 13.625rem;
        max-width: 13.625rem;
      `
        : `
        left: 2.5rem;
        right: auto;
        transform: none;
      `}
  display: flex;
  flex-direction: column;
  background-color: ${designColors.neutral.white};
  border-top: 0.0625rem solid #eaeaea;
  ${shadows.hover};
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.5s linear,
    visibility 0.5s linear;
  z-index: 3000;
  margin-top: 0.1875rem;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`

export const NavbarLi = styled.li<{ $hasAlignRightSubMenu?: boolean }>`
  display: inline-block;
  align-content: center;
  height: 2.25rem;
  max-height: 2.25rem;
  &:hover {
    display: block;
  }
  ${({ $hasAlignRightSubMenu }) =>
    $hasAlignRightSubMenu &&
    `
  position: relative;
  `}
  &:hover ${SubMenuContainer} {
    opacity: 1;
    visibility: visible;
  }

  &:hover ${NavbarLink}:after {
    width: 100%;
  }
`

export const ItemList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-height: 19.5625rem;
`

export const Item = styled.li`
  height: 1.875rem;
  max-height: 1.875rem;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  padding-bottom: 0.3125rem;
`

export const SubTitle = styled.h6`
  width: 100%;
  text-transform: uppercase;
  ${typography.subtitle1}
  margin: 0;
  margin-bottom: 0.75rem;
  padding: 0;
  height: 1.875rem;
  max-height: 1.875rem;

  @media screen and (max-width: 768px) {
    ${typography.overline3}
    font-weight: 600;
    margin: 0 0 0.625rem;
    color: ${designColors.neutral.darkGray};
  }
`

export const ItemLink = styled.a`
  ${typography.body2}
  margin-bottom: 0.625rem;
  text-decoration: none;
  color: ${designColors.neutral.veryDarkGray};
  white-space: nowrap;

  &:hover {
    text-decoration: none;
    color: ${designColors.primary.default};
  }

  @media screen and (max-width: 768px) {
    color: ${designColors.neutral.darkGray};
    text-decoration: none;
    margin: 0 0 0.5rem;
  }
`

export const ItemLinkBold = styled(ItemLink)`
  ${typography.subtitle1}
`
export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  width: 69.375rem;
  padding: 1.375rem clamp(1rem, 5vw, 2rem) 2.1875rem;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    gap: 1rem;
    width: fit-content;
    margin: 0;
    overflow: auto;
    height: 100%;
  }
`

export const ConditionsContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  width: 100%;
  padding: 1.375rem clamp(1rem, 5vw, 2rem) 2.1875rem;

  & ${Item} {
    height: unset;
    max-height: unset;
    padding-bottom: 0.625rem;
  }
  & ${ItemLink} {
    white-space: wrap;
  }
`

export const SellersDivider = styled.div`
  width: 0.0625rem;
  background-color: ${designColors.neutral.mediumGray};
  margin: auto 1.5625rem;
  height: 85%;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
`

export const SellersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5625rem;
  margin: 0 auto;
  width: 100%;
  max-width: 69.375rem;
  height: 25rem;
  padding: 1.375rem 2rem 2.1875rem;
`

export const RegionsContainer = styled.div`
  display: flex;
  gap: 6.25rem;
  margin: 0 auto;
  width: 69.375rem;
  height: 19.5625rem;
  padding: 1.375rem 1rem 2.1875rem;
`

export const TopSellersList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 12.5rem;
`

export const Column = styled.div`
  flex: 1;
  margin-right: 0.625rem;

  &:last-child {
    margin-right: 0;
  }
`

export const SellersColumn = styled(Column)`
  flex: 0;
  &:first-child {
    width: 15.625rem;
    max-width: 15.625rem;
  }

  &:last-child {
    flex: 1;
  }
`

export const AllSellersList = styled(ItemList)`
  height: 19.5625rem;
  max-height: 19.5625rem;
  column-gap: 2.1875rem;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #6b8cff, #a3a8f0);
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0.0625rem rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #4a6bff, #7a7ff0);
  }

  &::-webkit-scrollbar-track {
    background: ${designColors.neutral.mediumGray};
    border-radius: 0.25rem;
  }
`

// Mobile SubMenu Styles

export const MobileSubMenu = styled.div`
  background-color: ${designColors.neutral.white};
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 70%;
  height: 100%;
  max-height: 100%;
  z-index: 3000;
  overflow: auto;
  padding: 3.125rem 1.875rem;
`

export const MobileTitleRow = styled.div`
  display: flex;
  align-content: center;
  border-bottom: 0.0625rem solid ${designColors.neutral.mediumGray};
  margin-bottom: 1.5rem;

  & > *:first-child {
    // Target the first child (MobileTitleIcon)
    margin-right: auto; // Push it to the left
  }

  & > *:last-child {
    // Target the last child (MobileTitle)
    margin-right: auto; // Push it to the right, effectively centering it
  }
`

export const MobileColumn = styled.div``

export const MobileTitleIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 0.875rem;
  max-width: 0.875rem;
  max-height: 0.875rem;
`

export const MobileTitle = styled.h4`
  ${typography.subtitle2}
  line-height: 0.875rem;
  padding: 0 0 0.5rem;
  margin: 0 0 0.9375rem;
  color: ${designColors.neutral.darkGray};
`

export const MobileSubTitle = styled.h6`
  width: 100%;
  text-transform: uppercase;
  ${typography.subtitle3}
  margin: 0;
  padding: 0;
  height: 1.875rem;
  max-height: 1.875rem;
  font-weight: 600;
  color: ${designColors.neutral.darkGray};
`
export const MobileItemList = styled(ItemList)`
  flex-wrap: nowrap;
  overflow: visible;
  margin-bottom: 1.125rem;
`

export const SellersMobileItemList = styled(ItemList)`
  flex-wrap: nowrap;
  overflow: visible;
  margin-bottom: 1.125rem;

  &:first-of-type {
    border-bottom: 0.0625rem solid ${designColors.neutral.mediumGray};
  }
`

export const MobileItem = styled(Item)`
  margin-bottom: 0.625rem;
`
