'use client'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import {
  RegisterButton,
  MobileNavSectionTitle,
  MobileNavSection,
  MobileNavLinks,
  MobileNavLink,
} from './ui'
import navData from '../navData.json'

export default function MobileNavigation() {
  const { trackButtonClicked } = useAnalytics()
  const registerHref = '/acct/signup'

  const handleClickRegister = () => {
    // TODO: Make the entity_type dynamic based on the signed-in user's role
    trackButtonClicked('homepage', 'register_now', 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      cta_url: registerHref,
    })
  }

  return (
    <MobileNavLinks id="MobileNavLinks">
      <MobileNavSectionTitle>For Buyer</MobileNavSectionTitle>
      <MobileNavSection>
        {navData.forBuyer.map((item) => (
          <MobileNavLink key={item.href} href={item.href}>
            {item.name}
          </MobileNavLink>
        ))}
      </MobileNavSection>
      <MobileNavSectionTitle>For Seller</MobileNavSectionTitle>
      <MobileNavSection>
        {navData.forSeller.map((item) => (
          <MobileNavLink key={item.href} href={item.href}>
            {item.name}
          </MobileNavLink>
        ))}
      </MobileNavSection>
      <MobileNavSectionTitle>Support</MobileNavSectionTitle>
      <MobileNavSection>
        {navData.support.map((item) => (
          <MobileNavLink key={item.href} href={item.href}>
            {item.name}
          </MobileNavLink>
        ))}
      </MobileNavSection>
      <RegisterButton
        onClick={handleClickRegister}
        href={registerHref}
        size="small"
        appearance="primary"
      >
        Register Now
      </RegisterButton>
    </MobileNavLinks>
  )
}
