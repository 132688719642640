'use client'
import { styled } from 'styled-components'

import { designColors } from '@b-stock/bstock-react/theme'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 12.375rem;
  max-width: 12.375rem;
  height: 21.25rem;
  max-height: 21.25rem;
  padding: 0;
  flex-shrink: 0;
`

const SkeletonBlock = styled.div`
  background-color: ${designColors.neutral.lightGray};
  animation: pulse 1.5s ease-in-out infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`

const LogoBlock = styled(SkeletonBlock)`
  height: 3.125rem;
  min-height: 3.125rem;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  border-bottom: none;
  border-radius: 0.3125rem 0.3125rem 0 0;
`

const AuctionImageHolder = styled(SkeletonBlock)`
  height: 8.75rem;
  min-height: 8.75rem;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
`

const LotInfo = styled.div`
  min-height: 9.375rem;
  padding: 1.375rem 0.9375rem 0.9375rem;
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  border-top: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
`

const SkeletonText = styled(SkeletonBlock)`
  height: 1rem;
  line-height: 1.0625rem;
  margin-bottom: 0.6875rem;
  margin-right: auto;
`

const AuctionCardSkeleton = () => {
  return (
    <Wrapper>
      <LogoBlock />
      <AuctionImageHolder />
      <LotInfo>
        <SkeletonText style={{ width: '100%' }} />
        <SkeletonText style={{ width: '100%' }} />
        <SkeletonText style={{ width: '80%' }} />
        <SkeletonText style={{ width: '60%' }} />
      </LotInfo>
    </Wrapper>
  )
}

export default AuctionCardSkeleton
