'use client'
// [Seller Spotlight - WRH-7]

import Image from 'next/image'
import Link from 'next/link'
import { styled } from 'styled-components'

import { ButtonAsA } from '@b-stock/bstock-react'
import {
  breakpoints,
  designColors,
  typography,
  shadows,
} from '@b-stock/bstock-react/theme'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import type { Seller } from './sellerSpotlightData'
import { sellersSpotlight } from './sellerSpotlightData'
import Carousel, {
  CarouselContainer,
  CarouselItemWrapper,
} from '../common/carousel/Carousel'

interface SellerSpotlightCardProps {
  seller: Seller
}

const StyledSection = styled.section<{ $bgColor?: string }>`
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};
  display: flex;
  padding: 5rem 2.5rem;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media ${breakpoints.max.small} {
    padding: 2.5rem 0;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 85rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;

  ${CarouselContainer} {
    width: 100%;
    max-width: 77.75rem;
    @media ${breakpoints.max.small} {
      width: 100%;
      max-width: 100%;
    }
  }

  ${CarouselItemWrapper} {
    padding: 0 0.3125rem;
  }

  @media ${breakpoints.max.medium} {
    justify-content: center;
  }

  @media ${breakpoints.max.small} {
    gap: 1.25rem;
    touch-action: pan-x;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    margin: 0 auto;
  }
`
const ContentHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 77.75rem;
  padding: 0 1.6875rem;

  & > *:first-child {
    margin-right: auto;
  }

  @media ${breakpoints.max.small} {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`
const Title = styled.h3`
  ${typography.title2};
  font-weight: 600;
  color: ${designColors.neutral.veryDarkGray};
  margin: 0;
  padding: 0;
  white-space: nowrap;

  @media ${breakpoints.max.small} {
    ${typography.subtitle1};
  }
`
const ViewAllSellers = styled(ButtonAsA)`
  white-space: nowrap;
  margin: 0;
  padding: 0;

  &&:hover {
    background-color: transparent;
  }
`

const Card = styled.div<{ $bgColor: string }>`
  display: flex;
  border-radius: 1.5rem;
  ${shadows.multipleCards};
  width: 20rem;
  max-width: 20rem;
  flex-shrink: 0;
  padding: 2rem;
  background-color: ${({ $bgColor }) => $bgColor || designColors.neutral.white};
`

const CardLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  color: ${designColors.neutral.veryDarkGray};
`

const CardTitle = styled.p`
  ${typography.subtitle1};
  color: ${designColors.neutral.veryDarkGray};
  margin: 0;
  padding: 0;
`

const ImageContainer = styled.div`
  width: 5.4375rem;
  max-width: 5.4375rem;
  height: 5.4375rem;
  max-height: 5.4375rem;

  @media ${breakpoints.max.small} {
    width: 4.6875rem;
    max-width: 4.6875rem;
    height: 4.6875rem;
    max-height: 4.6875rem;
  }
`

const SellerLogo = styled(Image)`
  width: 5.4375rem;
  max-width: 5.4375rem;
  height: 5.4375rem;
  max-height: 5.4375rem;

  @media ${breakpoints.max.small} {
    width: 4.6875rem;
    max-width: 4.6875rem;
    height: 4.6875rem;
    max-height: 4.6875rem;
  }
`

const SellerSpotlightCard = ({ seller }: SellerSpotlightCardProps) => {
  const analytics = useAnalytics()

  const handleClickSeller = () => {
    // Segment analytics event and metadata transcribed from old wordpress code
    // TODO: Update entity_type to based on the role of the user once we
    // implement user authentication.
    analytics.trackButtonClicked(
      'homepage', // screen_name
      'homepage_seller', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        url: document.URL,
        referrer: document.referrer,
        seller_name_ua: seller.name.toLowerCase().replace(/\s+/g, '_'),
        cta_url: seller.link,
      }
    )
  }

  return (
    <Card $bgColor={seller.color}>
      <CardLink
        href={seller.link}
        onClick={handleClickSeller}
        target="_blank"
        rel="noopener"
      >
        <ImageContainer>
          <SellerLogo
            src={seller.logo}
            alt={`${seller.name} logo`}
            width={200}
            height={50}
          />
        </ImageContainer>
        <CardTitle>{seller.name}</CardTitle>
      </CardLink>
    </Card>
  )
}

export default function SellerSpotlightCarousel() {
  return (
    <StyledSection $bgColor={designColors.success.bg}>
      <Content>
        <ContentHeader>
          <Title>Seller Spotlight</Title>
          <ViewAllSellers
            size="large"
            appearance="tertiary"
            href="https://bstock.com/marketplaces/"
          >
            View All Sellers
          </ViewAllSellers>
        </ContentHeader>
        <Carousel>
          {sellersSpotlight.map((seller) => (
            <SellerSpotlightCard key={seller.name} seller={seller} />
          ))}
        </Carousel>
      </Content>
    </StyledSection>
  )
}
