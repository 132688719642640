interface RegionItem {
  name: string
  href: string
}

interface RegionHeader {
  title: string
}

interface RegionFooter {
  text: string
  href: string
}

interface Region {
  header: RegionHeader
  items: RegionItem[]
  footer: RegionFooter
}

export interface AllRegionsDataProps {
  unitedStates: Region
  canada: Region
  europe: Region
  ukAuctions: RegionFooter
}

const allRegionsData: AllRegionsDataProps = {
  unitedStates: {
    header: { title: 'United States' },
    items: [
      {
        name: 'New York',
        href: 'https://bstock.com/auctions/united-states/new-york/',
      },
      {
        name: 'New Jersey',
        href: 'https://bstock.com/auctions/united-states/new-jersey/',
      },
      {
        name: 'California',
        href: 'https://bstock.com/auctions/united-states/california/',
      },
      {
        name: 'Texas',
        href: 'https://bstock.com/auctions/united-states/texas/',
      },
      {
        name: 'Florida',
        href: 'https://bstock.com/auctions/united-states/florida/',
      },
    ],
    footer: {
      text: 'View All US Auctions',
      href: 'https://bstock.com/auctions/united-states/',
    },
  },
  canada: {
    header: { title: 'Canada' },
    items: [
      {
        name: 'Alberta',
        href: 'https://bstock.com/auctions/canada/alberta/',
      },
      {
        name: 'British Columbia',
        href: 'https://bstock.com/auctions/canada/british-columbia/',
      },
      {
        name: 'Quebec',
        href: 'https://bstock.com/auctions/canada/quebec/',
      },
      {
        name: 'Ontario',
        href: 'https://bstock.com/auctions/canada/ontario-canada/',
      },
    ],
    footer: {
      text: 'Shop All CA Auctions',
      href: 'https://bstock.com/auctions/canada/',
    },
  },
  europe: {
    header: { title: 'Europe' },
    items: [
      {
        name: 'Germany',
        href: 'https://bstock.com/auctions/germany/',
      },
      {
        name: 'Poland',
        href: 'https://bstock.com/auctions/poland-liquidation-auctions/',
      },
      {
        name: 'France',
        href: 'https://bstock.com/auctions/france/',
      },
      {
        name: 'Belgium',
        href: 'https://bstock.com/auctions/belgium-auctions/',
      },
      {
        name: 'Spain',
        href: 'https://bstock.com/auctions/spain/',
      },
    ],
    footer: {
      text: 'Shop All EU Auctions',
      href: 'https://bstock.com/auctions/europe/',
    },
  },
  ukAuctions: {
    text: 'Shop All UK Auctions',
    href: 'https://bstock.com/auctions/united-kingdom/',
  },
}

export default allRegionsData
