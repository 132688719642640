'use client'

import { styled } from 'styled-components'

import HeaderAuctionNavigation from './headerAuctionNavigation/HeaderAuctionNavigation'
import HeaderPrimaryNavigation from './headerPrimaryNavigation/HeaderPrimaryNavigation'
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
`

export const Header = () => {
  return (
    <HeaderContainer>
      <HeaderPrimaryNavigation />
      <HeaderAuctionNavigation />
    </HeaderContainer>
  )
}
