'use client'
import { useState } from 'react'

import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons'

import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import DropdownNavigation from './dropdownNavigation/DropdownNavigation'
import MobileNavigation from './mobileNavigation/MobileNavigation'
import SearchWidget from './SearchWidget'
import {
  HeaderDiv,
  NavBrand,
  BstockLogoImg,
  Nav,
  UserActivitySection,
  RegisterButton,
  IconContainer,
  StyledFAIcon,
  Overlay,
  MobileNavTrigger,
  MobileNav,
} from './ui'

export default function HeaderPrimaryNavigation() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const { trackButtonClicked } = useAnalytics()

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }

  const registerHref = '/acct/signup'

  const handleClickRegister = () => {
    // TODO: Make the entity_type dynamic based on the signed-in user's role
    trackButtonClicked('homepage', 'register_now', 'home_portal', 'buyer', {
      url: document.URL,
      referrer: document.referrer,
      cta_url: registerHref,
    })
  }

  return (
    <HeaderDiv
      id="headerDiv"
      data-gtm-event-position="header"
      data-gtm-element-type="header item"
    >
      <NavBrand
        id="navBrand"
        href="/"
        data-gtm-element-type="header logo"
        data-gtm-element-text="Logo"
      >
        <BstockLogoImg
          id="bstockLogoImg"
          src="/home-portal/images/bstock_logo.svg"
          alt="B-Stock Solutions, LLC."
          width={200}
          height={50}
        />
      </NavBrand>

      <SearchWidget />

      <Nav id="nav">
        <DropdownNavigation />
        <UserActivitySection>
          <RegisterButton
            href={registerHref}
            onClick={handleClickRegister}
            size="medium"
            appearance="primary"
          >
            Register Now
          </RegisterButton>
        </UserActivitySection>
      </Nav>

      <MobileNavTrigger id="mobileNavTrigger">
        <IconContainer $top="50%" $right="1.25rem">
          <StyledFAIcon
            id="openIcon"
            icon={faBars}
            onClick={toggleMobileNav}
            $size="1.375rem"
          />
        </IconContainer>
      </MobileNavTrigger>

      <Overlay $isOpen={isMobileNavOpen} onClick={toggleMobileNav} />
      <MobileNav id="mobileNav" $isOpen={isMobileNavOpen}>
        <IconContainer $top="1.75rem" $right="1.25rem">
          <StyledFAIcon
            id="openIcon"
            icon={faTimes}
            onClick={toggleMobileNav}
            $size="1.375rem"
          />
        </IconContainer>
        <MobileNavigation />
      </MobileNav>
    </HeaderDiv>
  )
}
