'use client'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'

import allConditions from '../auctionNavData/conditionsData'
import {
  MobileSubMenu,
  MobileTitleRow,
  MobileTitleIcon,
  MobileTitle,
  MobileSubTitle,
  MobileItemList,
  MobileItem,
  ItemLink,
} from '../ui'

interface ConditionsMobileSubMenuProps {
  onClose: () => void
  onClick?: (event: React.MouseEvent) => void
}

const ConditionsMobileSubMenu = ({
  onClose,
  onClick,
}: ConditionsMobileSubMenuProps) => {
  const data = allConditions
  const handleCloseSubMenu = () => {
    onClose()
  }
  return (
    <MobileSubMenu onClick={onClick}>
      <MobileTitleRow>
        <MobileTitleIcon icon={faChevronLeft} onClick={handleCloseSubMenu} />
        <MobileTitle>Shop by Condition</MobileTitle>
      </MobileTitleRow>
      <MobileSubTitle>{data.header.title}</MobileSubTitle>
      <MobileItemList>
        {data.items.map((item) => (
          <MobileItem key={item.name}>
            <ItemLink href={item.href}>{item.name}</ItemLink>
          </MobileItem>
        ))}
      </MobileItemList>
    </MobileSubMenu>
  )
}

export default ConditionsMobileSubMenu
