'use client'
import type { AllCategoriesDataProps } from '../auctionNavData/categoriesData'
import {
  CategoriesContainer,
  ItemList,
  Item,
  ItemLink,
  ItemLinkBold,
  SubTitle,
} from '../ui'

const CategoriesLayout = ({ data }: { data: AllCategoriesDataProps }) => {
  return (
    <CategoriesContainer>
      <SubTitle>{data.header.title}</SubTitle>
      <ItemList>
        {data.items.map((item) => (
          <Item key={item.name}>
            <ItemLink href={item.href}>{item.name}</ItemLink>
          </Item>
        ))}
        <Item>
          <ItemLinkBold href={data.footer.href}>
            {data.footer.text}
          </ItemLinkBold>
        </Item>
      </ItemList>
    </CategoriesContainer>
  )
}

export default CategoriesLayout
