'use client'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'

import allRegions from '../auctionNavData/regionsData'
import {
  MobileSubMenu,
  MobileTitleRow,
  MobileTitleIcon,
  MobileTitle,
  MobileColumn,
  MobileSubTitle,
  MobileItemList,
  MobileItem,
  ItemLink,
  ItemLinkBold,
} from '../ui'

interface RegionsMobileSubMenuProps {
  onClose: () => void
  onClick?: (event: React.MouseEvent) => void
}
const RegionsMobileSubMenu = ({
  onClose,
  onClick,
}: RegionsMobileSubMenuProps) => {
  const regions = [
    allRegions.unitedStates,
    allRegions.canada,
    allRegions.europe,
  ]
  const handleCloseSubMenu = () => {
    onClose()
  }
  return (
    <MobileSubMenu onClick={onClick}>
      <MobileTitleRow>
        <MobileTitleIcon icon={faChevronLeft} onClick={handleCloseSubMenu} />
        <MobileTitle>Shop by Region</MobileTitle>
      </MobileTitleRow>
      {regions.map((region) => (
        <MobileColumn key={region.header.title}>
          <MobileSubTitle>{region.header.title}</MobileSubTitle>
          <MobileItemList>
            {region.items.map((item) => (
              <MobileItem key={item.name}>
                <ItemLink href={item.href}>{item.name}</ItemLink>
              </MobileItem>
            ))}
            <MobileItem>
              <ItemLinkBold href={region.footer.href}>
                {region.footer.text}
              </ItemLinkBold>
            </MobileItem>
            {region.header.title === 'Europe' && (
              <MobileItem>
                <ItemLinkBold href={allRegions.ukAuctions.href}>
                  {allRegions.ukAuctions.text}
                </ItemLinkBold>
              </MobileItem>
            )}
          </MobileItemList>
        </MobileColumn>
      ))}
    </MobileSubMenu>
  )
}

export default RegionsMobileSubMenu
