import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/bestPlace/BestPlace.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/exploreBuying/ExploreBuying.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/home/node/app/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/heroBannerCarousel/HeroBannerCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/sellerSpotlight/SellerSpotlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/shopMarketplaceCarousel/ShopMarketplaceCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/components/shopMarketplaceCarousel/ShopMarketplaceGroup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/app/src/helpers/telemetry/TrackPageView.ts");
