'use client'
import { useEffect, useRef, useState } from 'react'
export const useNavbarScroll = () => {
  const [isScrollable, setIsScrollable] = useState(false)
  const navbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkOverflow = () => {
      if (navbarRef.current) {
        const isOverflowing =
          navbarRef.current.scrollWidth > navbarRef.current.clientWidth
        setIsScrollable(isOverflowing)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [])

  return { isScrollable, navbarRef }
}
