import { useQuery } from '@tanstack/react-query'

import { fetchData } from './api/fetchAuctionData'
import { parseCustomDate } from './dateUtils'
import type { Auction, AuctionResponse } from './types/auctionsTypes'

const SEVEN_MINUTES = 7 * 60 * 1000 // 7 minutes in milliseconds
const TEN_MINUTES = 10 * 60 * 1000 // 10 minutes in milliseconds

function filterExpiredAuctions(auctions: Auction[]) {
  const now = Date.now()
  return auctions.filter((auction) => {
    const endTimeMs = parseCustomDate(auction.endTime).getTime()
    return endTimeMs - now >= TEN_MINUTES
  })
}

function mergeUniqueAuctions(auctionSets: Auction[][]): Auction[] {
  // If there's only one auction set, we already know there are no duplicates
  if (auctionSets.length === 1) {
    return auctionSets[0]
  }

  const uniqueAuctions = [...auctionSets[0]]
  const uniqueAuctionIds = new Set(
    uniqueAuctions.map((auction) => auction.auctionId)
  )
  for (let i = 1; i < auctionSets.length; i++) {
    for (const auction of auctionSets[i]) {
      if (!uniqueAuctionIds.has(auction.auctionId)) {
        uniqueAuctions.push(auction)
        uniqueAuctionIds.add(auction.auctionId)
      }
    }
  }

  return uniqueAuctions
}

export function useAuctions(endpoint: string | string[]) {
  return useQuery<Auction[]>({
    queryKey: ['auctions', endpoint],
    queryFn: async () => {
      const endpoints = Array.isArray(endpoint) ? endpoint : [endpoint]
      const queryPromises = endpoints.map((endpoint) =>
        fetchData<AuctionResponse>(endpoint).then((data) =>
          filterExpiredAuctions(data.auctions)
        )
      )
      const auctionSets = await Promise.all(queryPromises)
      return mergeUniqueAuctions(auctionSets)
    },
    refetchInterval: SEVEN_MINUTES,
    refetchIntervalInBackground: true,
  })
}
