'use client'
import type { AllConditionsDataProps } from '../auctionNavData/conditionsData'
import { ConditionsContainer, Column, ItemList, Item, ItemLink } from '../ui'

interface ConditionsLayoutProps {
  data: AllConditionsDataProps
}

const ConditionsLayout: React.FC<ConditionsLayoutProps> = ({
  data,
}: {
  data: AllConditionsDataProps
}) => {
  return (
    <ConditionsContainer>
      <Column>
        <ItemList>
          {data.items.map((item) => (
            <Item key={item.name}>
              <ItemLink href={item.href}>{item.name}</ItemLink>
            </Item>
          ))}
        </ItemList>
      </Column>
    </ConditionsContainer>
  )
}

export default ConditionsLayout
