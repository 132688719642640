'use client'
import type { AllRegionsDataProps } from '../auctionNavData/regionsData'
import {
  RegionsContainer,
  Column,
  SubTitle,
  ItemList,
  Item,
  ItemLink,
  ItemLinkBold,
} from '../ui'

interface RegionsLayoutProps {
  data: AllRegionsDataProps
}

const RegionsLayout: React.FC<RegionsLayoutProps> = ({
  data,
}: {
  data: AllRegionsDataProps
}) => {
  const regions = [data.unitedStates, data.canada, data.europe]

  return (
    <RegionsContainer>
      {regions.map((region) => (
        <Column key={region.header.title}>
          <SubTitle>{region.header.title}</SubTitle>
          <ItemList>
            {region.items.map((item) => (
              <Item key={item.name}>
                <ItemLink href={item.href}>{item.name}</ItemLink>
              </Item>
            ))}
            <Item>
              <ItemLinkBold href={region.footer.href}>
                {region.footer.text}
              </ItemLinkBold>
            </Item>
            <Item>
              {region.header.title === 'Europe' && (
                <ItemLinkBold href={data.ukAuctions.href}>
                  {data.ukAuctions.text}
                </ItemLinkBold>
              )}
            </Item>
          </ItemList>
        </Column>
      ))}
    </RegionsContainer>
  )
}

export default RegionsLayout
