'use client'

import {
  Dropdown,
  NavLink,
  DropdownItem,
  DropdownLinkContainer,
  DDItemName,
  DDItemDescription,
} from './ui'
import navData from '../navData.json'

export default function DropdownNavigation() {
  return (
    <>
      <DropdownLinkContainer>
        <NavLink>For Buyers</NavLink>
        <Dropdown $columns={4} $fullWidth>
          {navData.forBuyer.map((item) => (
            <DropdownItem
              key={item.href}
              href={item.href}
              data-gtm-element-type="header subitem"
              data-gtm-element-text={item.name}
            >
              <DDItemName>{item.name}</DDItemName>
              <DDItemDescription>{item.description}</DDItemDescription>
            </DropdownItem>
          ))}
        </Dropdown>
      </DropdownLinkContainer>

      <DropdownLinkContainer>
        <NavLink>For Sellers</NavLink>
        <Dropdown $columns={4} $fullWidth>
          {navData.forSeller.map((item) => (
            <DropdownItem
              id="ITEM"
              key={item.href}
              href={item.href}
              data-gtm-element-type="header subitem"
              data-gtm-element-text={item.name}
            >
              <DDItemName>{item.name}</DDItemName>
              <DDItemDescription>{item.description}</DDItemDescription>
            </DropdownItem>
          ))}
        </Dropdown>
      </DropdownLinkContainer>

      <DropdownLinkContainer>
        <NavLink>Support</NavLink>
        <Dropdown $columns={2} $alignRight>
          {navData.support.map((item) => (
            <DropdownItem
              key={item.href}
              href={item.href}
              data-gtm-element-type="header subitem"
              data-gtm-element-text={item.name}
            >
              <DDItemName>{item.name}</DDItemName>
              <DDItemDescription>{item.description}</DDItemDescription>
            </DropdownItem>
          ))}
        </Dropdown>
      </DropdownLinkContainer>
    </>
  )
}
